<template>
  <h1>{{ $t('pageNotFound.message') }}</h1>
</template>

<script>
  export default {
    name: 'PageNotFound'
  };
</script>

<style>
  .pageNotFound .mainContent {
    padding-top: 2.5rem;
  }
</style>
